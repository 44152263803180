/**
 * @param {Number} type - 0: 业务咨询 1: 举报投诉
 */
import { getConsultationList, getComplainList } from '@api/customer';

export default type => {
  let differenceInfo;
  if (type) {
    differenceInfo = {
      typeText: '举报投诉',
      apiName: getComplainList,
      btnImgName: 'jbts-w'
    };
  } else {
    differenceInfo = {
      typeText: '业务咨询',
      apiName: getConsultationList,
      btnImgName: 'ywzx-w'
    };
  }
  return differenceInfo;
};
