<template>
  <div class="consulting-content">
    <!-- 导航栏 -->
    <NavBar :title="typeText"> </NavBar>

    <!-- 列表 -->
    <ul class="content pa-16">
      <li v-for="item in list" :key="item.id" @click="handleToDetail(item.id)">
        <div class="left">
          <p class="title text-truncate">
            {{ item.submitterContent }}
          </p>
          <p class="date">
            {{ formatToDateTime(item.submitterTime, 'yyyy.MM.dd hh:mm') }}
          </p>
        </div>
        <p :class="[{ 'color-primary': item.answererStatus }, 'right']">
          {{ item.answererStatusText }}
        </p>
      </li>
    </ul>

    <!-- 浮窗按钮 -->
    <div class="fix-btn" @click="handleToEdit">
      <Icon svgClass="icon-big" :name="btnImgName"></Icon>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon/index.vue';
import NavBar from '@/components/NavBar/index.vue';
import { defineComponent, ref, onBeforeMount } from '@vue/composition-api';
import { useRouter, useRoute } from '@/utils/compsitionHack';
import useGetDifferenceInfo from './hooks/useGetDifferenceInfo';
import { formatToDateTime } from '@wlhy-web-lib/shared';
import { Toast } from 'vant';
import { PageEnum } from '@/enums/pageEnum';

export default defineComponent({
  name: 'customer',
  components: {
    Icon,
    NavBar
  },
  setup() {
    const router = useRouter();
    const { query } = useRoute();
    const list = ref([]);

    const { typeText, apiName, btnImgName } = useGetDifferenceInfo(+query.type);

    onBeforeMount(async () => {
      // 获取列表数据
      try {
        const res = await apiName();
        if (res) {
          list.value = res.data;
        }
      } catch (err) {
        Toast.fail(err);
      }
    });

    // 跳转到编辑页
    const handleToEdit = () => {
      console.log(router);
      router.push({ path: PageEnum.CUSTOMER_EDIT, query: { type: +query.type } });
    };
    const handleToDetail = id => {
      router.push({ path: PageEnum.CUSTOMER_DETAIL, query: { type: +query.type, id } });
    };
    return {
      handleToEdit,
      typeText,
      btnImgName,
      list,
      formatToDateTime,
      handleToDetail
    };
  }
});
</script>
<style lang="less" scoped>
.consulting-content {
  .text-truncate {
    word-break: break-all;
  }
  .content {
    background: #fff;
    li {
      height: 0.72rem;
      border-bottom: 0.01rem solid #ebebeb;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        .title {
          width: 2.45rem;
          font-size: 0.16rem;
          font-weight: 400;
          line-height: 0.18rem;
          text-align: left;
          color: #333333;
        }
        .date {
          color: #bcbcbc;
          margin-top: 0.08rem;
          height: 0.17rem;
          font-size: 0.12rem;
          line-height: 0.17rem;
          font-weight: 400;
          text-align: left;
        }
      }
      .right {
        font-size: 0.14rem;
        font-weight: 400;
        text-align: right;
        color: #bcbcbc;
      }
    }
  }
  .fix-btn {
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background: #0076ff;
    position: fixed;
    right: 0.16rem;
    bottom: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
